.jobboard-floating-list {
    position: fixed;
    display: flex;
    left: 0;
    bottom: 0;
    z-index: 1000;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    padding: 1em;
    padding-right:100px;
    background: white;

    img {
        max-height: 20px;
        width: auto;
        object-fit: contain;
        margin: 0 10px;
    }
}