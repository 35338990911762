.step-card {
    margin-bottom: 1rem;
    min-height: 35rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;

    .card-header {
        min-height: 60px;
        border: none !important;

        .ribbon-label {
            background: #E04B4B;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }

    &.card-indeed {
        position: relative !important;

        .card-header {
            .ribbon-label {
                background: #3384FA;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/indeed-logo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
        
    &.card-linkedin {
        .card-header {
            .ribbon-label {
                background: #3083AC;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/linkedin-logo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-wttj {
        .card-header {
            .ribbon-label {
                color: black;
                background: rgb(255, 205, 0);
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 15px;
                top: 0;
                width: 12%;
                height: 12%;
                opacity: 0.2;
                background-image: url(../../../../../assets/images/WTTJ_LOGO_2020.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-talentcom {
        .card-header {
            .ribbon-label {
                background: #44195D;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 15%;
                height: 15%;
                opacity: 0.2;
                background-image: url(../../../../../assets/images/talent_com_logo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-hello-work {
        .card-header {
            .ribbon-label {
                background: black;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/hellowork-logo.svg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    
    &.card-poleemploi {
        .card-header {
            .ribbon-label {
                background: #314969;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 14%;
                height: 14%;
                opacity: 0.3;
                background-image: url(../../../../../assets/images/logo-france-travail.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    
    &.card-monster {
        .card-header {
            .ribbon-label {
                background: #704F9A;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0px;
                top: 20px;
                width: 20%;
                height: 20%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/monster-logo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-emploicollectivites {
        .card-header {
            .ribbon-label {
                background: #5c94bb;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 10px;
                top: 20px;
                width: 20%;
                height: 20%;
                opacity: 0.4;
                background-image: url(../../../../../assets/images/EmploiCollectivitesLogo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-emploiterritorial {
        .card-header {
            .ribbon-label {
                background: #00857b;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: -20px;
                top: 20px;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/EmploiTerritorialLogo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-choisirleservicepublic {
        .card-header {
            .ribbon-label {
                background: #e1000f;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: -20px;
                top: 20px;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/ChoisirLeServicePublicLogo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-apec {
        .card-header {
            .ribbon-label {
                background: #fbce32;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 20px;
                top: 20px;
                width: 14%;
                height: 14%;
                opacity: 0.2;
                background-image: url(../../../../../assets/images/apec-logo.svg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }

    &.card-leboncoin {
        .card-header {
            .ribbon-label {
                background: #ff6e14;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/LeBonCoinLogo.svg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-sfsp {
        .card-header {
            .ribbon-label {
                background: #66398e;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/SfspLogo.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-santepubliquefrance {
        .card-header {
            .ribbon-label {
                background: #00b1e6;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/SantePubliqueFranceLogo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-lagazettedescommunes {
        .card-header {
            .ribbon-label {
                background: #dab508;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/lagazettedescommunesLogo.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-meteojob {
        .card-header {
            .ribbon-label {
                background: #c5e6f1;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 20%;
                height: 20%;
                opacity: 0.4;
                background-image: url(../../../../../assets/images/meteojobLogo.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-optioncarriere {
        .card-header {
            .ribbon-label {
                background: #2062b0;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;
                height: 10%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/OptioncarriereLogo.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-fhf {
        .card-header {
            .ribbon-label {
                background: #00347c;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: -10px;
                top: -10px;
                width: 20%;
                height: 20%;
                opacity: 0.2;
                background-image: url(../../../../../assets/images/FhfLogo.jpg);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
    &.card-figaro {
        .card-header {
            .ribbon-label {
                background: #3275b4;
            }
        }

        .card-body {
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: 30%;
                height: 30%;
                opacity: 0.1;
                background-image: url(../../../../../assets/images/FigaroLogo.png);
                background-repeat: no-repeat;
                background-position: 50% 0;
                background-size: contain;
            }
        }
    }
}