.jobboard-card {
    position: relative;
    padding: 1em;
    opacity: 0.8;
    cursor: pointer;
    border:1px solid rgba(0, 0, 0, 0);
    height: 150px;

    &.checked, &:hover {
        opacity: 1;
        border:2px solid rgba($color: #009ef7, $alpha: 0.5);
        background-color: #009cf797;        
    }

    img {
        max-height: 50px;
        width: auto;
        object-fit: contain;
    } 

    .payment-type {
        background:#E04B4B;
        position: absolute;
        bottom:0;
        right:0;
        color:white;
        padding:3px;
        text-transform: uppercase;
        font-size:0.8rem;
        border-bottom-right-radius: var(--bs-card-border-radius);
    }

    .gratuit-type {
        background:#05a164;
        position: absolute;
        bottom:0;
        right:0;
        color:white;
        padding:3px;
        text-transform: uppercase;
        font-size:0.8rem;
        border-bottom-right-radius: var(--bs-card-border-radius);
    }

    .jobboard-card-informations {
        .jobboard-card-strong {
            font-weight: bold;
        }
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  