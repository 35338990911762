.page-diffusion {
    padding: 1em;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    
    &::before {
        content: ' ';
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 10%;
        height: 10%;
        opacity: 0.5;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        z-index:0;
    }

    h1 {
        position: fixed;
        top: 0;
        z-index: 1000;
        width: 100%;
        font-size: 2em;
        text-align: center;
        border-bottom: 3px solid #E04B4B;
        background: white;
        margin-bottom: 1em;
        display: none;
    }

    .diffusion-step {
        margin-bottom: 2em;
        &.step-disabled {
            opacity: 0.2;
        }
    }

    .floating-group {
        position:fixed;
        bottom: 15px;
        right:30px;

        i.action-icon {
                color: white;

                &.right {
                    margin-left:10px;
                }
                
                &.left {
                    margin-right:10px;
                }
        }
    }
}