.floating-group {
  position:fixed;
  bottom: 15px;
  right:30px;
  z-index: 99999;

  i.action-icon {
          color: white;

          &.right {
              margin-left:10px;
          }
          
          &.left {
              margin-right:10px;
          }
  }
}